*, *:before, *:after {
  box-sizing: border-box;
}

.HeaderAll {
  margin-bottom: 20px;
}

.Header1 {
  margin-left: 20px;
  font-size: 50px;
}

.Header2 {
  margin-left: 20px;
  font-size: 20px;
}

.NavigationBar {
  background-color: #eee;
}

.NavigationBar .nav-item {
  margin-left: 20px;
}

.NavigationBar a {
  color: #444;
}

.NavigationBar a:hover {
  background-color: #ddd;
}

.NavigationBar a:visited {
  color: #444;
}

.NavigationBar .nav-item .active {
  background-color: #def;
}

.BodyText {
  margin: 50px 70px 0px 70px;
  font-size: medium;
}

.PictureSliderDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PictureSliderButton {
  margin: 10px;
  padding: 10px 50px 10px 50px;
  background-color: #44f;
  color: white;
  font-weight: bolder;
  font-size: x-large;
  border-radius: 10px;
  border: 0px;
}

.PictureSliderButton:hover {
  background-color: #33f;
}

.PictureSliderCaption {
  margin-top: 10px;
}

.TimeLineTableHead {
  background-color: #33f;
  color: white;
  text-align: left;
  vertical-align: top;
  padding: 15px;
  border: 1px solid black;
}

.TimeLineTableRow:nth-child(even) {
    background-color: #eef;
    color: #000;
}

.TimeLineTableRow:hover {
  background-color: #fef;
}

.TimeLineTableElement {
  white-space: pre-line;
  text-align: left;
  vertical-align: top;
  padding: 15px;
  border: 1px solid black;
}

.Merci {
  font-size: x-large;
  font-weight: bold;
}

/* ******************************  Gästebuch *********************************************/

.GuestBookForm {
  margin-bottom: 20px;
}

.InputField, .FeedbackField {
  margin-bottom: 5px;
  font-weight: bold;
}

.BotcheckBox {
  margin-bottom: 5px;
  font-weight: bold;
}

.BotSwitch {
  font-size: 25px;
}

.BotcheckBox2 {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0px;
}

.FeedbackField {
  max-width: 100%;
  margin-bottom: 10px;
}

.GuestBookEntriesHeader {
  margin-top: 20px;
  margin-bottom: 10px;
}

.GuestBookEntryDiv {
  background-color: #eef;
  padding: 10px;
  margin-bottom: 10px;
}

.GuestBookEntryH6 {
  font-weight: bold;
  margin-bottom: 5px;
}

.GuestBookEntryText {
  white-space: pre-line;
  margin-bottom: 25px;
}

